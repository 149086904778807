<template>
  <div class="user-lottery-records">
    <h2>我的抽奖记录</h2>
    
    <div v-if="error" class="error-alert">
      <h3>{{ error.title }}</h3>
      <p>{{ error.description }}</p>
    </div>

    <div v-if="showBindCode" class="bind-code-container">
      <div class="bind-alert">
        <h3>请绑定您的账号</h3>
        <p>请复制以下绑定码到微信群中发送以完成绑定：</p>
        <textarea v-model="bindCode" readonly></textarea>
        <button @click="copyBindCode">复制绑定码</button>
      </div>
    </div>
    
    <div v-else-if="!error" class="records-container">
      <div class="filter-container">
        <label>
          <input type="checkbox" v-model="showAllRecords"> 显示全部记录
        </label>
      </div>

      <div v-if="loading" class="loading">加载中...</div>
      <div v-else-if="filteredRecords.length === 0" class="empty-records">暂无抽奖记录</div>
      <div v-else class="record-list">
        <div v-for="record in filteredRecords" :key="record.id" class="record-card">
          <div class="record-header" :class="{ 'winner': record.isWinner, 'non-winner': !record.isWinner }">
            <span class="prize-name">{{ record.isWinner ? record.prizeName : '未中奖' }}</span>
            <span v-if="record.isWinner" :class="['status-tag', record.isRedeemed ? 'redeemed' : 'unredeemed']">
              {{ record.isRedeemed ? '已兑换' : '未兑换' }}
            </span>
          </div>
          <div class="record-content">
            <p><strong>活动名称：</strong>{{ record.activityName }}</p>
            <p><strong>抽奖时间：</strong>{{ formatDate(record.drawTime) }}</p>
            <p v-if="record.isWinner"><strong>兑奖截止时间：</strong>{{ formatDate(record.redeemEndTime) }}</p>
            <p v-if="record.isWinner">
              <strong>兑换码：</strong>
              <span class="redeem-code">{{ record.redeemCode }}</span>
              <button v-if="!record.isRedeemed" @click="copyRedeemCode(record.redeemCode)" class="copy-button">复制</button>
            </p>
            <p><strong>用户昵称：</strong>{{ record.userNick }}</p>
            <p><strong>活动类型：</strong>{{ record.activityType === 0 ? '即时开奖' : '定时开奖' }}</p>
            <p v-if="record.activityType === 1">
              <strong>开奖时间：</strong>{{ formatDate(record.activityEndTime) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getUserLotteryRecords, getWxOpenId, getWxId } from '@/api';

export default {
  name: 'UserLotteryRecords',
  data() {
    return {
      records: [],
      loading: true,
      error: null,
      wxCode: null,
      wxOpenId: null,
      wxId: null,
      groupId: null,
      showBindCode: false,
      bindCode: '',
      showAllRecords: false,
    };
  },
  computed: {
    filteredRecords() {
      return this.showAllRecords ? this.records : this.records.filter(record => record.isWinner);
    }
  },
  methods: {
    
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    },
    async fetchRecords() {
      try {
        this.loading = true;
        if (!this.groupId || !this.wxId) {
          throw new Error('缺少必要的参数');
        }

        const response = await getUserLotteryRecords(this.groupId, this.wxId);
        if (response.data.code === 2000) {
          this.records = response.data.data;
        } else {
          throw new Error(response.data.message || '获取抽奖记录失败');
        }
      } catch (err) {
        this.setError('获取抽奖记录失败', err);
      } finally {
        this.loading = false;
      }
    },
    async getWxAuth() {
      try {
        // 步骤1: 获取微信OpenID
        const openIdResponse = await getWxOpenId(this.wxCode);
        if (openIdResponse.data.code === 0 && openIdResponse.data.data && openIdResponse.data.data.wxopenid) {
          this.wxOpenId = openIdResponse.data.data.wxopenid;
        } else {
          throw new Error(openIdResponse.data.msg || '获取微信OpenID失败');
        }
        
        // 步骤2: 获取微信ID
        const wxIdResponse = await getWxId(this.wxOpenId);
        if (wxIdResponse.data.code === 0 && wxIdResponse.data.data) {
          if (wxIdResponse.data.data.wxid) {
            this.wxId = wxIdResponse.data.data.wxid;
            localStorage.setItem('wxId', this.wxId);
          } else if (wxIdResponse.data.data.bindcode) {
            this.handleBindCode(wxIdResponse.data.data.bindcode);
          } else {
            throw new Error('获取微信ID或绑定码失败');
          }
        } else {
          throw new Error(wxIdResponse.data.msg || '获取微信ID失败');
        }
      } catch (error) {
        console.error('微信认证错误:', error);
        this.setError('微信授权失败', error);
      }
    },
    handleBindCode(bindCode) {
      this.showBindCode = true;
      this.bindCode = bindCode;
    },
    copyRedeemCode(code) {
      navigator.clipboard.writeText(code).then(() => {
        alert('兑换码已复制到剪贴板');
      }).catch(() => {
        alert('复制失败，请手动复制');
      });
    },
    copyBindCode() {
      navigator.clipboard.writeText(this.bindCode).then(() => {
        alert('绑定码已复制到剪贴板');
      }).catch(() => {
        alert('复制失败，请手动复制');
      });
    },
    getUrlParam(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    setError(title, error) {
      let description = '';
      if (typeof error === 'string') {
        description = error;
      } else if (error instanceof Error) {
        description = this.parseErrorMessage(error.message);
      } else if (error && error.response && error.response.data) {
        description = this.parseErrorMessage(error.response.data.msg);
      }
      this.error = { title, description };
    },
    parseErrorMessage(message) {
      try {
        const parsedMsg = JSON.parse(message);
        if (parsedMsg.errcode && parsedMsg.errmsg) {
          return `错误码: ${parsedMsg.errcode}, 错误信息: ${parsedMsg.errmsg}`;
        }
      } catch (e) {
        // 如果解析失败，返回原始消息
      }
      return message;
    },
    redirectToLogin() {
      const redirectUrl = encodeURIComponent(`https://lottery.snakelenas.top/user-lottery-records?groupId=${this.groupId}`);
      window.location.href = `https://platform.wumengyoupin.com/oauth/userbase.html?appid=wx6129e116b52c81d2&processcodeurl=${redirectUrl}`;
    },
  },
  async mounted() {
    console.log('Component mounted');
    this.groupId = this.getUrlParam('groupId');
    
    if (!this.groupId) {
      this.setError('参数错误', '缺少必要的参数 groupId，请确保从正确的链接进入');
      this.loading = false;
      return;
    }

    // 检查本地存储中是否有 wxId
    this.wxId = localStorage.getItem('wxId');

    if (this.wxId) {
      // 如果有 wxId，直接获取中奖记录
      await this.fetchRecords();
    } else {
      // 如果没有 wxId，检查 URL 中是否有 code
      this.wxCode = this.getUrlParam('code');
      
      if (this.wxCode) {
        // 如果有 code，进行微信认证
        try {
          await this.getWxAuth();
          if (this.wxId) {
            await this.fetchRecords();
          }
        } catch (error) {
          console.error('Error in authentication:', error);
          this.setError('认证失败', error);
        }
      } else {
        // 如果没有 code，重定向到登录页面
        this.redirectToLogin();
      }
    }

    this.loading = false;
  },
};
</script>

<style scoped>
.user-lottery-records {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
}
.filter-container {
  margin-bottom: 20px;
  text-align: right;
}

.filter-container label {
  cursor: pointer;
  user-select: none;
}

.record-header.winner {
  background-color: #3498db;
}

.record-header.non-winner {
  background-color: #95a5a6;
}
h2 {
  font-size: 24px;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.error-alert, .bind-alert {
  background-color: rgba(254, 240, 240, 0.9);
  color: #F56C6C;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.bind-alert {
  background-color: rgba(253, 246, 236, 0.9);
  color: #E6A23C;
}

.record-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.record-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.record-header {
  background-color: #3498db;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.prize-name {
  font-weight: bold;
  font-size: 18px;
}

.status-tag {
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 14px;
}

.redeemed {
  background-color: #2ecc71;
}

.unredeemed {
  background-color: #f1c40f;
  color: #34495e;
}

.record-content {
  padding: 15px;
}

.record-content p {
  margin: 10px 0;
  color: #34495e;
}

.redeem-code {
  background-color: #ecf0f1;
  color: #2c3e50;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}

.copy-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #2980b9;
}

.loading, .empty-records {
  text-align: center;
  padding: 30px;
  color: #7f8c8d;
  font-size: 18px;
}

textarea {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #bdc3c7;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}
</style>