import axios from 'axios';

const baseURL = process.env.VUE_APP_API_BASE_URL;

const api = axios.create({
  baseURL: baseURL,
});

const publicApi = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Auth
export const login = (userData) => api.post('/api/Auth/Login', userData);
export const getAllUsers = () => api.get('/api/Auth/GetAllUsers');
export const findUserByName = (userName) => api.get(`/api/Auth/FindByUserName?userName=${userName}`);
export const updateUser = (userId, userData) => api.put(`/api/Auth/UpdateUser/${userId}`, userData);
export const deleteUser = (userId) => api.delete(`/api/Auth/DeleteUser/${userId}`);
export const registerUser = (userData) => api.post('/api/Auth/Register', userData);

// Public ChatConfig API calls
export const getChatConfig = (groupId) => publicApi.get(`/api/ChatConfig/${groupId}`);
export const updateChatConfig = (config) => publicApi.post('/api/ChatConfig', config);
export const deleteChatConfig = (groupId) => publicApi.delete(`/api/ChatConfig/${groupId}`);
export const setChatConfigIsOpen = (groupId, isOpen) => 
  publicApi.put(`/api/ChatConfig/${groupId}/isOpen/${isOpen}`);




// Public ImageGenConfig API calls
export const getImageGenConfig = (groupId) => publicApi.get(`/api/ImageGenConfig/${groupId}`);
export const updateImageGenConfig = (config) => {
  // 确保 model 参数被正确传递
  const updatedConfig = {
    ...config,
    model: Number(config.model) // 确保 model 是数字类型
  };
  return publicApi.post('/api/ImageGenConfig', updatedConfig);
};
export const deleteImageGenConfig = (groupId) => publicApi.delete(`/api/ImageGenConfig/${groupId}`);
export const setImageGenConfigIsOpen = (groupId, isOpen) => 
  publicApi.put(`/api/ImageGenConfig/${groupId}/isOpen/${isOpen}`);



// Lottery API calls
export const getLotteryActivities = (groupId) => api.get(`/api/Lottery/activities/${groupId}`);
export const addLotteryActivity = (activity) => api.post('/api/Lottery/activity', activity);
export const updateLotteryActivity = (activity) => api.put('/api/Lottery/activity', activity);
export const deleteLotteryActivity = (activityId) => api.delete(`/api/Lottery/activity/${activityId}`);
export const getLotteryPrizes = (activityId) => api.get(`/api/Lottery/prizes/${activityId}`);
export const addLotteryPrize = (prize) => api.post('/api/Lottery/prize', prize);
export const updateLotteryPrize = (prize) => api.put('/api/Lottery/prize', prize);
export const deleteLotteryPrize = (prizeId) => api.delete(`/api/Lottery/prize/${prizeId}`);
export const getLotteryRecords = (activityId) => api.get(`/api/Lottery/records/${activityId}`);
export const getLotteryParticipants = (activityId) => api.get(`/api/Lottery/participants/${activityId}`);

export const redeemLotteryPrize = (recordId) => api.post('/api/Lottery/redeemPrize?recordId=' +recordId);
export const getUserLotteryRecords = (groupId, userWxid) => 
  api.get('/api/Lottery/userRecords', { params: { groupId, userWxid } });
export const getWxOpenId = (code) => api.get(`/api/lottery/wx-openid?code=${code}`);
export const getWxId = (wxOpenId) => api.get(`/api/lottery/wx-id?wxOpenId=${wxOpenId}`);
// export const getUserLotteryRecords = (groupId, userWxid) => axios.get(`/api/lottery/user-records?groupId=${groupId}&userWxid=${userWxid}`);

// Joke API calls
export const getJokeConfig = (groupId) => publicApi.get(`/api/JokeConfig/${groupId}`);
export const updateJokeConfig = (config) => publicApi.post('/api/JokeConfig', config);
export const deleteJokeConfig = (groupId) => publicApi.delete(`/api/JokeConfig/${groupId}`);
export const setJokeConfigIsEnabled = (groupId, isEnabled) => 
   publicApi.put(`/api/JokeConfig/${groupId}/isEnabled/${isEnabled}`);
export const fetchAndSaveJokes = (groupId) => publicApi.post(`/api/JokeConfig/${groupId}/fetchJokes`);
export const getRandomJoke = (groupId) => publicApi.get(`/api/JokeConfig/${groupId}/randomJoke`);

// Weather API calls
export const getWeatherConfig = (groupId) => publicApi.get(`/api/WeatherConfig/${groupId}`);
export const updateWeatherConfig = (config) => publicApi.post('/api/WeatherConfig', config);
export const deleteWeatherConfig = (groupId) => publicApi.delete(`/api/WeatherConfig/${groupId}`);
export const setWeatherConfigIsEnabled = (groupId, isEnabled) => 
   publicApi.put(`/api/WeatherConfig/${groupId}/isEnabled/${isEnabled}`);
export const getWeather = (groupId, city) => publicApi.get(`/api/WeatherConfig/${groupId}/weather`, { params: { city } });

// History API calls
export const getHistoryConfig = (groupId) => publicApi.get(`/api/HistoryConfig/${groupId}`);
export const updateHistoryConfig = (config) => publicApi.post('/api/HistoryConfig', config);
export const deleteHistoryConfig = (groupId) => publicApi.delete(`/api/HistoryConfig/${groupId}`);
export const setHistoryConfigIsEnabled = (groupId, isEnabled) => 
   publicApi.put(`/api/HistoryConfig/${groupId}/isEnabled/${isEnabled}`);
export const getTodayInHistory = (groupId) => publicApi.get(`/api/HistoryConfig/${groupId}/todayInHistory`);

// 壁纸配置 API 调用
export const getWallpaperConfig = (groupId) => publicApi.get(`/api/WallpaperConfig/${groupId}`);
export const updateWallpaperConfig = (config) => publicApi.post('/api/WallpaperConfig', config);
export const setWallpaperConfigIsEnabled = (groupId, isEnabled) => 
   publicApi.put(`/api/WallpaperConfig/${groupId}/isEnabled/${isEnabled}`);
export const getRandomWallpaper = (groupId) => publicApi.get(`/api/WallpaperConfig/${groupId}/randomWallpaper`);
export const fetchAndSaveWallpapers = (groupId) => publicApi.post(`/api/WallpaperConfig/${groupId}/fetchWallpapers`);


// Forwarding Management API calls
export const forwardinglogin = (userData) => api.post('/api/ForwardingUser/login', userData);
export const updateCredentials = (credentialsData) => api.put('/api/ForwardingUser/credentials', credentialsData);
export const getUsers = () => api.get('/api/ForwardingUser/users');
export const addUser = (userData) => api.post('/api/ForwardingUser/user', userData);
export const forwardingupdateUser = (userData) => api.put('/api/ForwardingUser/user', userData);
export const forwardingdeleteUser = (userId) => api.delete(`/api/ForwardingUser/user/${userId}`);
export const getForwardingSets = () => api.get('/api/ForwardingUser/forwardingsets');
export const addForwardingSet = (setData) => api.post('/api/ForwardingUser/forwardingset', setData);
export const updateForwardingSet = (setData) => api.put('/api/ForwardingUser/forwardingset', setData);
export const deleteForwardingSet = (setId) => api.delete(`/api/ForwardingUser/forwardingset/${setId}`);
export const startWechat = (botWxid) => api.get(`/api/ForwardingUser/startwechat?botWxid=${botWxid}`);
export const listVisibleBots = (botWxid, flag) => api.get(`/api/ForwardingUser/listvisiblebots?botWxid=${botWxid}&flag=${flag}`);
export const getBotFriendList = (botWxid, flag) => api.get(`/api/ForwardingUser/friendlist?botWxid=${botWxid}&flag=${flag}`);
export const getBotGroupList = (botWxid, flag) => api.get(`/api/ForwardingUser/grouplist?botWxid=${botWxid}&flag=${flag}`);
export const exitWechat = (botWxid, flag) => api.get(`/api/ForwardingUser/exitwechat?botWxid=${botWxid}&flag=${flag}`);
export const sendMessage = (sendMessageRequest) => {
  return api.post('/api/ForwardingUser/sendmessage', sendMessageRequest);
};

export default api;