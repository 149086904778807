<!-- HistoryConfig.vue -->
<template>
    <div v-if="groupId" class="history-config">
      <h2>群组 {{ groupId }} 的历史上的今天配置</h2>
      <el-form v-if="config" :model="config" label-width="120px">
        <el-form-item label="是否启用">
          <el-switch v-model="config.isEnabled" @change="updateIsEnabled"></el-switch>
        </el-form-item>
        <el-form-item label="App ID">
          <el-input v-model="config.appId" placeholder="请输入 App ID"></el-input>
        </el-form-item>
        <el-form-item label="App Secret">
          <el-input v-model="config.appSecret" placeholder="请输入 App Secret" show-password></el-input>
        </el-form-item>
        <el-form-item label="包含详情">
          <el-switch v-model="config.needDetails"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateConfig">更新配置</el-button>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <h3>历史上的今天</h3>
      <el-button @click="getTodayInHistory" :disabled="!config || !config.isEnabled">获取历史上的今天</el-button>
      <el-card v-if="historyEvents.length > 0" class="history-events">
        <div v-for="event in historyEvents" :key="event.title" class="history-event">
          <h4>{{ event.year }}年{{ event.month }}月{{ event.day }}日：{{ event.title }}</h4>
          <p v-if="config.needDetails">{{ event.details }}</p>
          <img v-if="event.picUrl" :src="event.picUrl" :alt="event.title" class="event-image">
        </div>
      </el-card>
    </div>
    <div v-else>
      <h2>错误：未提供群组 ID</h2>
      <p>请确保在 URL 中包含有效的群组 ID。</p>
    </div>
  </template>
  
  <script>
  import { getHistoryConfig, updateHistoryConfig, setHistoryConfigIsEnabled, getTodayInHistory } from '@/api';
  
  export default {
    name: 'HistoryConfig',
    props: ['roomwxid'],
    data() {
      return {
        config: null,
        historyEvents: [],
        groupId : ""
      };
    },
    created() {
        this.groupId = this.roomwxid
      if (this.groupId) {
        this.fetchConfig();
      }
    },
    methods: {
      async fetchConfig() {
        try {
          const response = await getHistoryConfig(this.groupId);
          if (response.data.code === 2000) {
            this.config = response.data.data;
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('获取历史配置失败:', error);
          this.$message.error('获取历史配置失败');
        }
      },
      async updateConfig() {
        try {
          const response = await updateHistoryConfig(this.config);
          if (response.data.code === 2000) {
            this.$message.success('历史配置更新成功');
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('更新历史配置失败:', error);
          this.$message.error('更新历史配置失败');
        }
      },
      async updateIsEnabled(isEnabled) {
        try {
          const response = await setHistoryConfigIsEnabled(this.groupId, isEnabled);
          if (response.data.code === 2000) {
            this.$message.success(`历史上的今天功能已${isEnabled ? '启用' : '禁用'}`);
          } else {
            this.$message.error(response.data.message);
            // 如果API调用失败，恢复开关状态
            this.config.isEnabled = !isEnabled;
          }
        } catch (error) {
          console.error('更新启用状态失败:', error);
          this.$message.error('更新启用状态失败');
          // 如果API调用失败，恢复开关状态
          this.config.isEnabled = !isEnabled;
        }
      },
      async getTodayInHistory() {
        try {
          const response = await getTodayInHistory(this.groupId);
          if (response.data.code === 2000) {
            this.historyEvents = response.data.data;
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('获取历史上的今天失败:', error);
          this.$message.error('获取历史上的今天失败');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .history-config {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  .history-events {
    margin-top: 20px;
  }
  .history-event {
    margin-bottom: 20px;
  }
  .event-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
  }
  </style>