<!-- WeatherConfig.vue -->
<template>
    <div v-if="groupId" class="weather-config">
      <h2>群组 {{ groupId }} 的天气配置</h2>
      <el-form v-if="config" :model="config" label-width="120px">
        <el-form-item label="是否启用">
          <el-switch v-model="config.isEnabled" @change="updateIsEnabled"></el-switch>
        </el-form-item>
        <el-form-item label="默认城市">
          <el-input v-model="config.defaultCity" placeholder="请输入默认城市"></el-input>
        </el-form-item>
        <el-form-item label="App ID">
          <el-input v-model="config.appId" placeholder="请输入 App ID"></el-input>
        </el-form-item>
        <el-form-item label="App Secret">
          <el-input v-model="config.appSecret" placeholder="请输入 App Secret" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateConfig">更新配置</el-button>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <h3>天气查询</h3>
      <el-form :inline="true" @submit.native.prevent="getWeather">
        <el-form-item>
          <el-input v-model="queryCity" placeholder="输入城市名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getWeather">查询天气</el-button>
        </el-form-item>
      </el-form>
      <el-card class="weather-info" v-if="weather">
        <p><strong>城市：</strong>{{ weather.address }}</p>
        <p><strong>温度：</strong>{{ weather.temp }}</p>
        <p><strong>天气：</strong>{{ weather.weather }}</p>
        <p><strong>风向：</strong>{{ weather.windDirection }}</p>
        <p><strong>风力：</strong>{{ weather.windPower }}</p>
        <p><strong>湿度：</strong>{{ weather.humidity }}</p>
        <p><strong>报告时间：</strong>{{ weather.reportTime }}</p>
      </el-card>
    </div>
    <div v-else>
      <h2>错误：未提供群组 ID</h2>
      <p>请确保在 URL 中包含有效的群组 ID。</p>
    </div>
  </template>
  
  <script>
  import { getWeatherConfig, updateWeatherConfig, setWeatherConfigIsEnabled, getWeather } from '@/api';
  
  export default {
    name: 'WeatherConfig',
    props: ['roomwxid'],
    data() {
      return {
        config: null,
        queryCity: '',
        weather: null,
        groupId: null,
      };
    },
    created() {
        this.groupId = this.roomwxid;
      if (this.groupId) {
        this.fetchConfig();
      }
    },
    methods: {
      async fetchConfig() {
        try {
          const response = await getWeatherConfig(this.groupId);
          if (response.data.code === 2000) {
            this.config = response.data.data;
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('获取天气配置失败:', error);
          this.$message.error('获取天气配置失败');
        }
      },
      async updateConfig() {
        try {
          const response = await updateWeatherConfig(this.config);
          if (response.data.code === 2000) {
            this.$message.success('天气配置更新成功');
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('更新天气配置失败:', error);
          this.$message.error('更新天气配置失败');
        }
      },
      async updateIsEnabled(isEnabled) {
        try {
          const response = await setWeatherConfigIsEnabled(this.groupId, isEnabled);
          if (response.data.code === 2000) {
            this.$message.success(`天气功能已${isEnabled ? '启用' : '禁用'}`);
          } else {
            this.$message.error(response.data.message);
            // 如果API调用失败，恢复开关状态
            this.config.isEnabled = !isEnabled;
          }
        } catch (error) {
          console.error('更新启用状态失败:', error);
          this.$message.error('更新启用状态失败');
          // 如果API调用失败，恢复开关状态
          this.config.isEnabled = !isEnabled;
        }
      },
      async getWeather() {
        try {
          const response = await getWeather(this.groupId, this.queryCity);
          if (response.data.code === 2000) {
            this.weather = response.data.data;
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('获取天气信息失败:', error);
          this.$message.error('获取天气信息失败');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .weather-config {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  .weather-info {
    margin-top: 20px;
  }
  </style>