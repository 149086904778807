<template>
  <div v-if="groupId" class="chat-config">
    <h2>群组 {{ groupId }} 的聊天配置</h2>
    <el-form v-if="config" :model="config" label-width="120px">
      <el-form-item label="是否开启">
        <el-switch v-model="config.isOpen" @change="updateIsOpen"></el-switch>
      </el-form-item>
      <el-form-item label="模型">
        <el-select v-model="config.model" placeholder="选择模型">
          <el-option
            v-for="model in modelOptions"
            :key="model.value"
            :label="model.label"
            :value="model.value">
            <span style="float: left">{{ model.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">
              {{ model.price }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="系统内容">
        <div class="system-content-wrapper">
          <el-input
            type="textarea"
            v-model="config.systemContent"
            :rows="3"
            placeholder="请输入用于定义AI助手角色和行为的系统指令"
          ></el-input>
          <el-tooltip
            class="item"
            effect="dark"
            :content="systemContentHelp"
            placement="right">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <span class="system-content-hint">例如："你是一个智能助理，擅长回答各种问题。"</span>
      </el-form-item>
      <el-form-item label="最大令牌数">
        <el-input v-model.number="config.maxToken" placeholder="请输入最大令牌数">
          <template #append>tokens</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updateConfig">更新配置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div v-else>
    <h2>错误：未提供群组 ID</h2>
    <p>请确保在 URL 中包含有效的群组 ID。</p>
  </div>
</template>

<script>
import { getChatConfig, updateChatConfig, setChatConfigIsOpen } from '@/api';

export default {
  name: 'ChatConfig',
  props: ['botwxid', 'roomwxid'],
  data() {
    return {
     groupId : "",
  
      config: null,
      modelOptions: [
        { value: 'xiaPro/Qwen/Qwen2-7B-Instruct', label: '通义千问2 7B指令版', price: '¥0.45/1M tokens' },
        { value: 'Pro/Qwen/Qwen2-1.5B-Instruct', label: '通义千问2 1.5B指令版', price: '¥0.24/1M tokens' },
        { value: 'Pro/Qwen/Qwen1.5-7B-Chat', label: '通义千问1.5 7B对话版', price: '¥0.45/1M tokens' },
        { value: 'Pro/THUDM/glm-4-9b-chat', label: 'GLM-4 9B对话版', price: '¥0.70/1M tokens' },
        { value: 'Pro/THUDM/chatglm3-6b', label: 'ChatGLM3 6B', price: '¥0.45/1M tokens' },
        { value: 'Pro/01-ai/Yi-1.5-9B-Chat-16K', label: '义语1.5 9B对话版 (16K)', price: '¥0.52/1M tokens' },
        { value: 'Pro/01-ai/Yi-1.5-6B-Chat', label: '义语1.5 6B对话版', price: '¥0.45/1M tokens' },
        { value: 'Pro/internlm/internlm2_5-7b-chat', label: '书生浦语2 7B对话版', price: '¥0.45/1M tokens' },
        { value: 'Pro/google/gemma-2-9b-it', label: 'Gemma 2 9B指令版', price: '¥0.70/1M tokens' },
        { value: 'Pro/meta-llama/Meta-Llama-3.1-8B-Instruct', label: 'Llama 3.1 8B指令版', price: '¥0.52/1M tokens' },
        { value: 'Pro/meta-llama/Meta-Llama-3-8B-Instruct', label: 'Llama 3 8B指令版', price: '¥0.52/1M tokens' },
        { value: 'Pro/mistralai/Mistral-7B-Instruct-v0.2', label: 'Mistral 7B指令版 v0.2', price: '¥0.45/1M tokens' },
        { value: 'Qwen/Qwen2-72B-Instruct', label: '通义千问2 72B指令版', price: '¥4.23/1M tokens' },
        { value: 'Qwen/Qwen2-57B-A14B-Instruct', label: '通义千问2 57B指令版', price: '¥1.36/1M tokens' },
        { value: 'Qwen/Qwen1.5-110B-Chat', label: '通义千问1.5 110B对话版', price: '¥4.23/1M tokens' },
        { value: 'Qwen/Qwen1.5-32B-Chat', label: '通义千问1.5 32B对话版', price: '¥1.36/1M tokens' },
        { value: 'Qwen/Qwen1.5-14B-Chat', label: '通义千问1.5 14B对话版', price: '¥0.80/1M tokens' },
        { value: '01-ai/Yi-1.5-34B-Chat-16K', label: '义语1.5 34B对话版 (16K)', price: '¥1.36/1M tokens' },
        { value: 'deepseek-ai/DeepSeek-Coder-V2-Instruct', label: 'DeepSeek编码器 V2指令版', price: '¥1.43/1M tokens' },
        { value: 'deepseek-ai/DeepSeek-V2-Chat', label: 'DeepSeek V2对话版', price: '¥1.43/1M tokens' },
        { value: 'deepseek-ai/deepseek-llm-67b-chat', label: 'DeepSeek 67B对话版', price: '¥1.10/1M tokens' },
        { value: 'meta-llama/Meta-Llama-3.1-405B-Instruct', label: 'Llama 3.1 405B指令版', price: '¥21.10/1M tokens' },
        { value: 'meta-llama/Meta-Llama-3-70B-Instruct', label: 'Llama 3 70B指令版', price: '¥4.23/1M tokens' },
        { value: 'meta-llama/Meta-Llama-3.1-70B-Instruct', label: 'Llama 3.1 70B指令版', price: '¥4.23/1M tokens' },
        { value: 'mistralai/Mixtral-8x7B-Instruct-v0.1', label: 'Mixtral 8x7B指令版 v0.1', price: '¥1.36/1M tokens' },
        { value: 'google/gemma-2-27b-it', label: 'Gemma 2 27B指令版', price: '¥1.36/1M tokens' },
        { value: 'Qwen/Qwen2-7B-Instruct', label: '通义千问2 7B指令版 ', price: '¥0.15/1M tokens' },
        { value: 'Qwen/Qwen2-1.5B-Instruct', label: '通义千问2 1.5B指令版 ', price: '¥0.1/1M tokens' },
        { value: 'Qwen/Qwen1.5-7B-Chat', label: '通义千问1.5 7B对话版 ', price: '¥0.15/1M tokens' },
        { value: 'THUDM/glm-4-9b-chat', label: 'GLM-4 9B对话版 ', price: '¥0.25/1M tokens' },
        { value: 'THUDM/chatglm3-6b', label: 'ChatGLM3 6B ', price: '¥0.18/1M tokens' },
        { value: '01-ai/Yi-1.5-9B-Chat-16K', label: '义语1.5 9B对话版 (16K) ', price: '¥0.30/1M tokens' },
        { value: '01-ai/Yi-1.5-6B-Chat', label: '义语1.5 6B对话版 ', price: '¥0.25/1M tokens' },
        { value: 'google/gemma-2-9b-it', label: 'Gemma 2 9B指令版 ', price: '¥0.35/1M tokens' },
        { value: 'internlm/internlm2_5-7b-chat', label: '书生浦语2 7B对话版 ', price: '¥0.25/1M tokens' },
        { value: 'meta-llama/Meta-Llama-3-8B-Instruct', label: 'Llama 3 8B指令版 ', price: '¥0.32/1M tokens' },
        { value: 'meta-llama/Meta-Llama-3.1-8B-Instruct', label: 'Llama 3.1 8B指令版 ', price: '¥0.32/1M tokens' },
        { value: 'mistralai/Mistral-7B-Instruct-v0.2', label: 'Mistral 7B指令版 v0.2 ', price: '¥0.25/1M tokens' }
      ],
      systemContentHelp: `
        系统内容用于定义AI助手的角色、行为和背景。
        它可以包含以下信息：
        1. 助手的角色描述（如："你是一个历史教师"）
        2. 性格特征
        3. 需要遵循的指令或规则
        4. 对话的背景或上下文

        例如，如果您需要一个导游，可以这样设置：
        "我想让你扮演一个导游。我会告诉你我的位置，你需要推荐附近的景点。
        有时我也会告诉你我想参观的地方类型。你还应该推荐与我第一个位置相近的类似类型的地方。"

        系统内容有助于AI更好地理解其在对话中的角色，从而提供更准确、更相关的回答。
      `.trim()
    };
  },
  created() {
    this.groupId = this.roomwxid;
    if (this.groupId) {
      this.fetchConfig();
    }
  },
  methods: {
    async fetchConfig() {
      try {
        const response = await getChatConfig(this.groupId);
        if (response.data.code === 2000) {
          this.config = response.data.data;
        } else {
          this.$message.error(response.data.message);
        }
      } catch (error) {
        console.error('获取聊天配置失败:', error);
        this.$message.error('获取聊天配置失败');
      }
    },
    async updateConfig() {
      try {
        // 确保 maxToken 是数字
        this.config.maxToken = parseInt(this.config.maxToken, 10);
        if (isNaN(this.config.maxToken)) {
          throw new Error('最大令牌数必须是有效的数字');
        }
        const response = await updateChatConfig(this.config);
        if (response.data.code === 2000) {
          this.$message.success('聊天配置更新成功');
        } else {
          this.$message.error(response.data.message);
        }
      } catch (error) {
        console.error('更新聊天配置失败:', error);
        this.$message.error(error.message || '更新聊天配置失败');
      }
    },
    async updateIsOpen(isOpen) {
      try {
        const response = await setChatConfigIsOpen(this.groupId, isOpen);
        if (response.data.code === 2000) {
          this.$message.success(`聊天配置现在已${isOpen ? '开启' : '关闭'}`);
        } else {
          this.$message.error(response.data.message);
          // 如果API调用失败，恢复开关状态
          this.config.isOpen = !isOpen;
        }
      } catch (error) {
        console.error('更新开启状态失败:', error);
        this.$message.error('更新开启状态失败');
        // 如果API调用失败，恢复开关状态
        this.config.isOpen = !isOpen;
      }
    },
  },
};
</script>

<style scoped>
.chat-config {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
.system-content-wrapper {
  display: flex;
  align-items: flex-start;
}
.system-content-wrapper .el-textarea {
  flex-grow: 1;
}
.system-content-wrapper .el-icon-question {
  margin-left: 10px;
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
.system-content-hint {
  font-size: 12px;
  color: #909399;
  margin-top: 5px;
  display: block;
}
</style>