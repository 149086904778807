<template>
  <div v-loading="loading" class="user-management">
    <h2>用户管理</h2>
    <div class="management-header">
      <el-input
        v-model="searchUsername"
        placeholder="用户名"
        class="input-with-select"
      >
        <el-button slot="append" icon="el-icon-search" @click="searchUser"></el-button>
      </el-input>
      <el-button type="primary" @click="showAddUserDialog">添加用户</el-button>
      <el-button type="danger" @click="logout">登出</el-button>
    </div>

    <div class="config-links">
      <el-button type="text" @click="goToChatConfig">Chat Configuration</el-button>
      <el-button type="text" @click="goToImageGenConfig">Image Generation Configuration</el-button>
    </div>

    <!-- 用户表格 -->
    <el-table
      :data="userList"
      style="width: 100%"
      border
    >
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column prop="userName" label="用户名" width="120"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="role" label="角色" width="100"></el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button @click="editUser(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="showChangePasswordDialog(scope.row)" type="text" size="small">修改密码</el-button>
          <el-button @click="deleteUser(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加/编辑用户对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form :model="userForm" label-width="80px">
        <el-form-item label="用户名">
          <el-input v-model="userForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码" v-if="dialogTitle === '添加用户'">
          <el-input v-model="userForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" v-if="dialogTitle === '编辑用户'">
          <el-input v-model="userForm.email"></el-input>
        </el-form-item>
        <el-form-item label="角色" v-if="dialogTitle === '编辑用户'">
          <el-select v-model="userForm.role" placeholder="请选择角色">
            <el-option label="管理员" value="admin"></el-option>
            <el-option label="用户" value="user"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUserForm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码对话框 -->
    <el-dialog title="修改密码" :visible.sync="passwordDialogVisible" width="30%">
      <el-form :model="passwordForm" label-width="100px">
        <el-form-item label="新密码">
          <el-input v-model="passwordForm.newPassword" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input v-model="passwordForm.confirmPassword" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPasswordChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserManagement',
  data() {
    return {
      loading: false,
      searchUsername: '',
      userList: [],
      dialogVisible: false,
      passwordDialogVisible: false,
      dialogTitle: '',
      userForm: {
        id: null,
        userName: '',
        password: '',
        email: '',
        role: ''
      },
      passwordForm: {
        userId: null,
        newPassword: '',
        confirmPassword: ''
      },
      editMode: false
    };
  },
  methods: {
    ...mapActions('user', ['getAllUsers', 'findUserByName', 'updateUser', 'deleteUser', 'registerUser']),
    async fetchAllUsers() {
      this.loading = true;
      try {
        this.userList = await this.getAllUsers();
      } catch (error) {
        console.error('获取用户列表失败:', error);
        this.$message.error('获取用户列表失败');
      } finally {
        this.loading = false;
      }
    },
    async searchUser() {
      if (!this.searchUsername) {
        await this.fetchAllUsers();
        return;
      }
      this.loading = true;
      try {
        const user = await this.findUserByName(this.searchUsername);
        this.userList = user ? [user] : [];
      } catch (error) {
        console.error('搜索用户失败:', error);
        this.$message.error('搜索用户失败');
      } finally {
        this.loading = false;
      }
    },
    showChangePasswordDialog(user) {
      this.passwordForm.userId = user.id;
      this.passwordForm.newPassword = '';
      this.passwordForm.confirmPassword = '';
      this.passwordDialogVisible = true;
    },
    async submitPasswordChange() {
      if (this.passwordForm.newPassword !== this.passwordForm.confirmPassword) {
        this.$message.error('两次输入的密码不一致');
        return;
      }

      try {
        await this.updateUser({
          userId: this.passwordForm.userId,
          userData: { newPassword: this.passwordForm.newPassword }
        });
        this.$message.success('密码修改成功');
        this.passwordDialogVisible = false;
      } catch (error) {
        console.error('修改密码失败:', error);
        this.$message.error(error.message || '修改密码失败');
      }
    },
    editUser(user) {
      this.dialogTitle = '编辑用户';
      this.userForm = { ...user };
      this.dialogVisible = true;
      this.editMode = true;
    },
    async deleteUser(user) {
      try {
        await this.$confirm('确认删除该用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.deleteUser(user.id);
        this.$message.success('用户已删除');
        await this.fetchAllUsers();
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除用户失败:', error);
          this.$message.error(error.message || '删除用户失败');
        }
      }
    },
    showAddUserDialog() {
      this.dialogTitle = '添加用户';
      this.userForm = {
        id: null,
        userName: '',
        password: '',
        email: '',
        role: 'user'  // 默认设置为普通用户
      };
      this.dialogVisible = true;
      this.editMode = false;
    },
    async submitUserForm() {
      try {
        if (this.editMode) {
          await this.updateUser({ userId: this.userForm.id, userData: this.userForm });
          this.$message.success('用户信息已更新');
        } else {
          await this.registerUser(this.userForm);
          this.$message.success('添加用户成功');
        }
        this.dialogVisible = false;
        await this.fetchAllUsers();
      } catch (error) {
        console.error('操作失败:', error);
        this.$message.error(error.message || '操作失败');
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userName');
      this.$router.push('/login');
    },
    goToChatConfig() {
      this.$router.push('/chat-config');
    },
    goToImageGenConfig() {
      this.$router.push('/image-gen-config');
    }
  },
  mounted() {
    this.fetchAllUsers();
  }
};
</script>

<style scoped>
.user-management {
  padding: 20px;
}
.management-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.input-with-select {
  width: 300px;
}
.config-links {
  margin-bottom: 20px;
}
</style>