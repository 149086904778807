<template>
  <div class="forwarding-management">
    <!-- 顶部导航栏 -->
    <el-header class="header">
      <h2 class="title">转发管理系统</h2>
      <div class="header-actions" v-if="user">
        <el-button type="text" @click="handleLogout" class="logout-button">登出</el-button>
        <el-tooltip content="设置" placement="bottom">
          <el-button type="text" @click="showSettingsDialog" class="settings-button">
            <i class="el-icon-setting"></i>
          </el-button>
        </el-tooltip>
      </div>
    </el-header>

    <el-main>
      <!-- 登录表单 -->
      <el-card v-if="!user" class="login-card" shadow="hover">
        <h3 class="card-title">登录</h3>
        <el-form @submit.native.prevent="handleLogin" :model="loginForm" label-width="80px">
          <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' }]">
            <el-input v-model="loginForm.username" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]">
            <el-input v-model="loginForm.password" type="password" placeholder="密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" :loading="loading" class="full-width-button">登录</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <template v-else>
        <!-- 用户管理（仅管理员） -->
        <el-card v-if="user.role === 0" class="card-section">
          <div slot="header" class="clearfix">
            <span>用户管理</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="showAddUserDialog">添加用户</el-button>
          </div>
          <el-table :data="users" style="width: 100%">
            <el-table-column prop="username" label="用户名" width="180"></el-table-column>
            <el-table-column prop="role" label="角色" width="100">
              <template #default="scope">
                {{ scope.row.role === 0 ? '管理员' : '员工' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template #default="scope">
                <el-button size="mini" @click="showEditUserDialog(scope.row)" type="primary">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleDeleteUser(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <!-- 转发设置 -->
        <el-card class="card-section">
          <div slot="header" class="clearfix">
            <span>转发设置</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="showAddForwardingSetDialog">添加转发设置</el-button>
          </div>
          <el-table :data="forwardingSets" style="width: 100%">
            <el-table-column prop="name" label="名称" width="180"></el-table-column>
            <el-table-column label="操作" width="200">
              <template #default="scope">
                <el-button size="mini" @click="showEditForwardingSetDialog(scope.row)" type="primary">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleDeleteForwardingSet(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <!-- 微信管理 -->
        <el-card class="card-section">
          <div slot="header" class="clearfix">
            <span>微信管理</span>
          </div>
          <el-button type="primary" @click="handleStartWechat" :loading="loading">启动微信</el-button>
          <el-table v-if="visibleBots.length > 0" :data="visibleBots" style="width: 100%; margin-top: 20px;">
            <el-table-column label="头像" width="100">
              <template #default="scope">
                <el-avatar :src="scope.row.headimg" size="small"></el-avatar>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称" width="150"></el-table-column>
            <el-table-column prop="botwxid" label="机器人WXID" width="200"></el-table-column>
            <el-table-column label="操作" width="120">
              <template #default="scope">
                <el-button size="mini" type="primary" @click="handleSelectBot(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <template v-if="selectedBot">
            <el-divider></el-divider>
            <el-button type="danger" @click="handleExitWechat" :loading="loading">退出微信</el-button>
            <el-form @submit.native.prevent="handleSendMessage" :model="messageForm" label-width="120px" class="message-form">
              <!-- 排除列表选择 -->
              <el-form-item label="排除列表" prop="exclusionList">
                <el-checkbox-group v-model="messageForm.exclusionList">
                  <el-checkbox
                    v-for="item in allRecipients"
                    :key="item.wxid"
                    :label="item.wxid"
                  >{{ `${item.nick}(${item.wxid})` }}</el-checkbox>
                </el-checkbox-group>
                <div v-if="messageForm.exclusionList.length > 10" class="error-message">
                  排除列表最多只能选择10个好友或群组。
                </div>
                <div v-else>
                  这里是排除列表，最多可以选择十个好友或群组不群发消息。
                </div>
              </el-form-item>
              <el-form-item label="转发设置" prop="forwardingSetId" :rules="[{ required: true, message: '请选择转发设置', trigger: 'change' }]">
                <el-select v-model="messageForm.forwardingSetId" placeholder="选择转发设置">
                  <el-option
                    v-for="set in forwardingSets"
                    :key="set.id"
                    :label="set.name"
                    :value="set.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" native-type="submit" :loading="loading">发送消息</el-button>
              </el-form-item>
            </el-form>
          </template>
        </el-card>
      </template>

      <!-- 添加用户对话框 -->
      <el-dialog :visible.sync="addUserDialogVisible" title="添加用户" width="90%" :close-on-click-modal="false">
        <el-form :model="newUser" @submit.native.prevent="handleAddUser" label-width="80px">
          <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' }]">
            <el-input v-model="newUser.username" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]">
            <el-input type="password" v-model="newUser.password" placeholder="密码"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="role" :rules="[{ required: true, message: '请选择角色', trigger: 'change' }]">
            <el-select v-model="newUser.role" placeholder="请选择角色">
              <el-option :value="0" label="管理员"></el-option>
              <el-option :value="1" label="员工"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" :loading="loading">添加</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 编辑用户对话框 -->
      <el-dialog :visible.sync="editUserDialogVisible" title="编辑用户" width="90%" :close-on-click-modal="false">
        <el-form :model="editUser" @submit.native.prevent="handleEditUser" label-width="80px">
          <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' }]">
            <el-input v-model="editUser.username" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="editUser.password" placeholder="密码（留空表示不修改）"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="role" :rules="[{ required: true, message: '请选择角色', trigger: 'change' }]">
            <el-select v-model="editUser.role" placeholder="请选择角色">
              <el-option :value="0" label="管理员"></el-option>
              <el-option :value="1" label="员工"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" :loading="loading">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 添加转发设置对话框 -->
      <el-dialog :visible.sync="addForwardingSetDialogVisible" title="添加转发设置" width="90%" :close-on-click-modal="false">
        <el-form :model="newForwardingSet" @submit.native.prevent="handleAddForwardingSet" label-width="80px">
          <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]">
            <el-input v-model="newForwardingSet.name" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="微信机器人ID" prop="WechatBotId" :rules="[{ required: true, message: '请输入微信机器人ID', trigger: 'blur' }]">
            <el-input v-model="newForwardingSet.WechatBotId" placeholder="微信机器人ID" disabled></el-input>
          </el-form-item>
          
          <el-form-item label="消息内容">
            <el-button type="dashed" @click="addForwardingItem" style="width: 100%;">添加消息类型</el-button>
          </el-form-item>
          
          <div v-for="(item, index) in newForwardingSet.items" :key="index" class="forwarding-item">
            <el-divider>消息 {{ index + 1 }}</el-divider>
            
            <el-form-item :label="'类型 ' + (index + 1)" :prop="'items.' + index + '.type'" :rules="[{ required: true, message: '请选择消息类型', trigger: 'change' }]">
              <el-select v-model="item.type" placeholder="选择消息类型" @change="onTypeChange(index)">
                <el-option label="文本" :value="0"></el-option>
                <el-option label="图片" :value="1"></el-option>
                <el-option label="文件" :value="2"></el-option>
                <el-option label="小程序" :value="3"></el-option>
                <el-option label="分享链接" :value="4"></el-option>
              </el-select>
            </el-form-item>
            
            <!-- 根据类型动态展示输入字段 -->
            <template v-if="item.type === 0">
              <el-form-item label="内容" :prop="'items.' + index + '.content'" :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]">
                <el-input v-model="item.content" placeholder="文本内容"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 1">
              <el-form-item label="图片 URL" :prop="'items.' + index + '.imageUrl'" :rules="[{ required: true, message: '请输入图片 URL', trigger: 'blur' }]">
                <el-input v-model="item.imageUrl" placeholder="图片 URL"></el-input>
              </el-form-item>
              <el-form-item label="文件名" :prop="'items.' + index + '.fileName'" :rules="[{ required: true, message: '请输入文件名', trigger: 'blur' }]">
                <el-input v-model="item.fileName" placeholder="文件名"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 4">
              <el-form-item label="标题" :prop="'items.' + index + '.title'" :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                <el-input v-model="item.title" placeholder="标题"></el-input>
              </el-form-item>
              <el-form-item label="内容" :prop="'items.' + index + '.content'" :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]">
                <el-input type="textarea" v-model="item.content" placeholder="内容"></el-input>
              </el-form-item>
              <el-form-item label="跳转 URL" :prop="'items.' + index + '.jumpUrl'" :rules="[{ required: true, message: '请输入跳转 URL', trigger: 'blur' }]">
                <el-input v-model="item.jumpUrl" placeholder="跳转 URL"></el-input>
              </el-form-item>
              <el-form-item label="应用" :prop="'items.' + index + '.app'" :rules="[{ required: true, message: '请输入应用', trigger: 'blur' }]">
                <el-input v-model="item.app" placeholder="应用"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 3">
              <el-form-item label="标题" :prop="'items.' + index + '.title'" :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                <el-input v-model="item.title" placeholder="标题"></el-input>
              </el-form-item>
              <el-form-item label="内容" :prop="'items.' + index + '.content'" :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]">
                <el-input type="textarea" v-model="item.content" placeholder="内容"></el-input>
              </el-form-item>
              <el-form-item label="跳转路径" :prop="'items.' + index + '.jumpPath'" :rules="[{ required: true, message: '请输入跳转路径', trigger: 'blur' }]">
                <el-input v-model="item.jumpPath" placeholder="跳转路径"></el-input>
              </el-form-item>
              <el-form-item label="Gh" :prop="'items.' + index + '.gh'" :rules="[{ required: true, message: '请输入 Gh', trigger: 'blur' }]">
                <el-input v-model="item.gh" placeholder="Gh"></el-input>
              </el-form-item>
              <el-form-item label="路径" :prop="'items.' + index + '.path'" :rules="[{ required: true, message: '请输入路径', trigger: 'blur' }]">
                <el-input v-model="item.path" placeholder="路径"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 2">
              <el-form-item label="文件 URL" :prop="'items.' + index + '.fileUrl'" :rules="[{ required: true, message: '请输入文件 URL', trigger: 'blur' }]">
                <el-input v-model="item.fileUrl" placeholder="文件 URL"></el-input>
              </el-form-item>
            </template>
            
            <el-form-item>
              <el-button type="danger" @click="removeForwardingItem(index)">删除此消息</el-button>
            </el-form-item>
          </div>
          
          <el-form-item>
            <el-button type="primary" @click="handleAddForwardingSet" :loading="loading">添加</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 编辑转发设置对话框 -->
      <el-dialog :visible.sync="editForwardingSetDialogVisible" title="编辑转发设置" width="90%" :close-on-click-modal="false">
        <el-form :model="editForwardingSet" @submit.native.prevent="handleEditForwardingSet" label-width="80px">
          <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]">
            <el-input v-model="editForwardingSet.name" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="微信机器人ID" prop="WechatBotId" :rules="[{ required: true, message: '请输入微信机器人ID', trigger: 'blur' }]">
            <el-input v-model="editForwardingSet.WechatBotId" placeholder="微信机器人ID" disabled></el-input>
          </el-form-item>
          
          <el-form-item label="消息内容">
            <el-button type="dashed" @click="addForwardingItemEdit" style="width: 100%;">添加消息类型</el-button>
          </el-form-item>
          
          <div v-for="(item, index) in editForwardingSet.items" :key="index" class="forwarding-item">
            <el-divider>消息 {{ index + 1 }}</el-divider>
            
            <el-form-item :label="'类型 ' + (index + 1)" :prop="'items.' + index + '.type'" :rules="[{ required: true, message: '请选择消息类型', trigger: 'change' }]">
              <el-select v-model="item.type" placeholder="选择消息类型" @change="onTypeChangeEdit(index)">
                <el-option label="文本" :value="0"></el-option>
                <el-option label="图片" :value="1"></el-option>
                <el-option label="文件" :value="2"></el-option>
                <el-option label="小程序" :value="3"></el-option>
                <el-option label="分享链接" :value="4"></el-option>
              </el-select>
            </el-form-item>
            
            <!-- 根据类型动态展示输入字段 -->
            <template v-if="item.type === 0">
              <el-form-item label="内容" :prop="'items.' + index + '.content'" :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]">
                <el-input v-model="item.content" placeholder="文本内容"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 1">
              <el-form-item label="图片 URL" :prop="'items.' + index + '.imageUrl'" :rules="[{ required: true, message: '请输入图片 URL', trigger: 'blur' }]">
                <el-input v-model="item.imageUrl" placeholder="图片 URL"></el-input>
              </el-form-item>
              <el-form-item label="文件名" :prop="'items.' + index + '.fileName'" :rules="[{ required: true, message: '请输入文件名', trigger: 'blur' }]">
                <el-input v-model="item.fileName" placeholder="文件名"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 4">
              <el-form-item label="标题" :prop="'items.' + index + '.title'" :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                <el-input v-model="item.title" placeholder="标题"></el-input>
              </el-form-item>
              <el-form-item label="内容" :prop="'items.' + index + '.content'" :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]">
                <el-input type="textarea" v-model="item.content" placeholder="内容"></el-input>
              </el-form-item>
              <el-form-item label="跳转 URL" :prop="'items.' + index + '.jumpUrl'" :rules="[{ required: true, message: '请输入跳转 URL', trigger: 'blur' }]">
                <el-input v-model="item.jumpUrl" placeholder="跳转 URL"></el-input>
              </el-form-item>
              <el-form-item label="应用" :prop="'items.' + index + '.app'" :rules="[{ required: true, message: '请输入应用', trigger: 'blur' }]">
                <el-input v-model="item.app" placeholder="应用"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 3">
              <el-form-item label="标题" :prop="'items.' + index + '.title'" :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                <el-input v-model="item.title" placeholder="标题"></el-input>
              </el-form-item>
              <el-form-item label="内容" :prop="'items.' + index + '.content'" :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]">
                <el-input type="textarea" v-model="item.content" placeholder="内容"></el-input>
              </el-form-item>
              <el-form-item label="跳转路径" :prop="'items.' + index + '.jumpPath'" :rules="[{ required: true, message: '请输入跳转路径', trigger: 'blur' }]">
                <el-input v-model="item.jumpPath" placeholder="跳转路径"></el-input>
              </el-form-item>
              <el-form-item label="Gh" :prop="'items.' + index + '.gh'" :rules="[{ required: true, message: '请输入 Gh', trigger: 'blur' }]">
                <el-input v-model="item.gh" placeholder="Gh"></el-input>
              </el-form-item>
              <el-form-item label="路径" :prop="'items.' + index + '.path'" :rules="[{ required: true, message: '请输入路径', trigger: 'blur' }]">
                <el-input v-model="item.path" placeholder="路径"></el-input>
              </el-form-item>
            </template>
            
            <template v-if="item.type === 2">
              <el-form-item label="文件 URL" :prop="'items.' + index + '.fileUrl'" :rules="[{ required: true, message: '请输入文件 URL', trigger: 'blur' }]">
                <el-input v-model="item.fileUrl" placeholder="文件 URL"></el-input>
              </el-form-item>
            </template>
            
            <el-form-item>
              <el-button type="danger" @click="removeForwardingItemEdit(index)">删除此消息</el-button>
            </el-form-item>
          </div>
          
          <el-form-item>
            <el-button type="primary" @click="handleEditForwardingSet" :loading="loading">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 设置对话框 -->
      <el-dialog :visible.sync="settingsDialogVisible" title="账号设置" width="90%" :close-on-click-modal="false">
        <el-form :model="credentialsForm" @submit.native.prevent="handleUpdateCredentials" label-width="100px">
          <el-form-item label="新用户名" prop="newUsername" :rules="[{ required: true, message: '请输入新用户名', trigger: 'blur' }]">
            <el-input v-model="credentialsForm.newUsername" placeholder="新用户名"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="credentialsForm.newPassword" placeholder="新密码（留空表示不修改）"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" :loading="loading">更新</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 二维码对话框 -->
      <el-dialog :visible.sync="qrCodeVisible" title="扫描二维码" width="90%">
        <img :src="qrCode" alt="二维码" class="qr-code-image" />
        <p>请使用微信扫描此二维码</p>
        <el-button type="primary" @click="handleScanComplete" :loading="loading">扫描完成</el-button>
      </el-dialog>
    </el-main>
  </div>
</template>

  
  <script>
  import { Message } from 'element-ui';
  
  import {
    forwardinglogin,
    updateCredentials,
    getUsers,
    addUser,
    forwardingupdateUser,
    forwardingdeleteUser,
    getForwardingSets,
    addForwardingSet,
    updateForwardingSet,
    deleteForwardingSet,
    startWechat,
    listVisibleBots,
    getBotFriendList,
    getBotGroupList,
    exitWechat,
    sendMessage
  } from '@/api';
  
  export default {
    name: 'ForwardingManagement',
    props: ['roomwxid', 'botwxid'], // 接收 roomwxid 和 botwxid 作为 props
    data() {
      return {
        user: null,
        users: [],
        forwardingSets: [],
        visibleBots: [],
        friends: [],
        groups: [],
        qrCode: '',
        flag: '',
        selectedBot: null,
        loading: false,
        qrCodeVisible: false,
        addUserDialogVisible: false,
        editUserDialogVisible: false,
        addForwardingSetDialogVisible: false,
        editForwardingSetDialogVisible: false,
        settingsDialogVisible: false,
        loginForm: {
          username: '',
          password: ''
        },
        credentialsForm: {
          newUsername: '',
          newPassword: ''
        },
        messageForm: {
          receiverId: [],
          forwardingSetId: '',
          exclusionList: []
        },
        newUser: {
          id: 0,
          username: '',
          password: '',
          role: 1,
          botwxid:this.botwxid
        },
        editUser: {
          id: 0,
          username: '',
          password: '',
          role: 1,
          botwxid:this.botwxid
        },
        newForwardingSet: {
          name: '',
          WechatBotId: '', // 添加 WechatBotId
          items: [] // 包含多个 ForwardingItem
        },
        editForwardingSet: {
          id: 0,
          name: '',
          WechatBotId: '', // 添加 WechatBotId
          items: [] // 包含多个 ForwardingItem
        }
      };
    },
    computed: {
      allRecipients() {
        // 合并好友和群组为一个列表，用于排除列表
        return [
          ...this.friends.map(f => ({ wxid: f.wxid, nick: f.nick })),
          ...this.groups.map(g => ({ wxid: g.wxid, nick: g.nick }))
        ];
      }
    },
    created() {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        this.user = JSON.parse(storedUser);
        this.fetchForwardingSets();
        if (this.user.role === 0) {
          this.fetchUsers();
        }
        // 从 props 获取 WechatBotId
        this.newForwardingSet.WechatBotId = this.botwxid;
        this.editForwardingSet.WechatBotId = this.botwxid;
        // 默认调用 listVisibleBots
        this.fetchVisibleBots();
      }
    },
    methods: {
      // 用户登录
      async handleLogin() {
        this.loading = true;
        try {
          const response = await forwardinglogin(this.loginForm);
          console.log(response);
          
          this.user = response.data.user;
          localStorage.setItem('user', JSON.stringify(this.user));
          localStorage.setItem('token', response.data.token);
          Message.success('登录成功');
          this.fetchForwardingSets();
          if (this.user.role === 0) {
            this.fetchUsers();
          }
          // 从 props 获取 WechatBotId
          this.newForwardingSet.WechatBotId = this.botwxid;
          this.editForwardingSet.WechatBotId = this.botwxid;
          // 默认调用 listVisibleBots
          this.fetchVisibleBots();
        } catch (error) {
          Message.error('登录失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 用户登出
      handleLogout() {
        this.user = null;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        Message.success('已成功登出');
      },
  
      // 显示设置对话框
      showSettingsDialog() {
        this.settingsDialogVisible = true;
        this.credentialsForm = {
          newUsername: this.user.username,
          newPassword: ''
        };
      },
  
      // 更新用户凭据
      async handleUpdateCredentials() {
        if (!this.credentialsForm.newUsername && !this.credentialsForm.newPassword) {
          Message.warning('请填写新的用户名或密码');
          return;
        }
        this.loading = true;
        try {
          await updateCredentials(this.credentialsForm);
          Message.success('凭据更新成功');
          if (this.credentialsForm.newUsername) {
            this.user.username = this.credentialsForm.newUsername;
            localStorage.setItem('user', JSON.stringify(this.user));
          }
          this.settingsDialogVisible = false;
          this.credentialsForm.newPassword = '';
        } catch (error) {
          Message.error('更新凭据失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 获取所有用户
      async fetchUsers() {
        this.loading = true;
        try {
          const response = await getUsers();
          this.users = response.data;
        } catch (error) {
          Message.error('获取用户列表失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 显示添加用户对话框
      showAddUserDialog() {
        this.addUserDialogVisible = true;
        this.newUser = {
          id: 0,
          username: '',
          password: '',
          role: 1,
          botWxid:this.botwxid
        };
      },
  
      // 添加新用户
      async handleAddUser() {
        if (!this.newUser.username || !this.newUser.password) {
          Message.error('用户名和密码不能为空');
          return;
        }
        this.loading = true;
        try {
          await addUser(this.newUser);
          Message.success('用户添加成功');
          this.addUserDialogVisible = false;
          this.fetchUsers();
        } catch (error) {
          Message.error('添加用户失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 显示编辑用户对话框
      showEditUserDialog(user) {
        this.editUserDialogVisible = true;
        this.editUser = { ...user, password: '' };
      },
  
      // 编辑用户信息
      async handleEditUser() {
        if (!this.editUser.username) {
          Message.error('用户名不能为空');
          return;
        }
        this.loading = true;
        try {
          await forwardingupdateUser(this.editUser);
          Message.success('用户更新成功');
          this.editUserDialogVisible = false;
          this.fetchUsers();
        } catch (error) {
          Message.error('更新用户失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 删除用户
      async handleDeleteUser(userId) {
        this.loading = true;
        try {
          await forwardingdeleteUser(userId);
          Message.success('用户删除成功');
          this.fetchUsers();
        } catch (error) {
          Message.error('删除用户失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 获取所有转发设置
      async fetchForwardingSets() {
        this.loading = true;
        try {
          const response = await getForwardingSets();
          this.forwardingSets = response.data;
        } catch (error) {
          Message.error('获取转发设置失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 显示添加转发设置对话框
      showAddForwardingSetDialog() {
        this.addForwardingSetDialogVisible = true;
        this.newForwardingSet = {
          name: '',
          WechatBotId: this.botwxid, // 设置 WechatBotId 通过 props 传递
          items: []
        };
      },
  
      // 添加新的 ForwardingItem（添加转发设置对话框）
      addForwardingItem() {
        this.newForwardingSet.items.push({
          type: null, // 初始化为 null，整数类型
          content: '',
          imageUrl: '',
          fileName: '',
          fileUrl: '',
          title: '',
          jumpUrl: '',
          app: '',
          jumpPath: '',
          gh: '',
          path: ''
        });
      },
  
      // 删除指定的 ForwardingItem（添加转发设置对话框）
      removeForwardingItem(index) {
        this.newForwardingSet.items.splice(index, 1);
      },
  
      // 当消息类型改变时，清空相关字段（添加转发设置对话框）
      onTypeChange(index) {
        const item = this.newForwardingSet.items[index];
        switch(item.type) {
          case 0: // Text
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          case 1: // Image
            item.content = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          case 4: // ShareLink
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          case 3: // MiniProgram
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            break;
          case 2: // File
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          default:
            // 清空所有字段
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
        }
      },
  
      // 处理添加转发设置
      async handleAddForwardingSet() {
        // 验证每个 ForwardingItem 的必填字段
        for (let i = 0; i < this.newForwardingSet.items.length; i++) {
          const item = this.newForwardingSet.items[i];
          if (item.type === null || item.type === undefined) {
            Message.error(`第 ${i + 1} 条消息类型未选择`);
            return;
          }
          switch(item.type) {
            case 0: // Text
              if (!item.content) {
                Message.error(`第 ${i + 1} 条文本消息内容未填写`);
                return;
              }
              break;
            case 1: // Image
              if (!item.imageUrl || !item.fileName) {
                Message.error(`第 ${i + 1} 条图片消息的图片 URL 或文件名未填写`);
                return;
              }
              break;
            case 4: // ShareLink
              if (!item.title || !item.content || !item.jumpUrl || !item.app) {
                Message.error(`第 ${i + 1} 条分享链接消息的标题、内容、跳转 URL 或应用未填写`);
                return;
              }
              break;
            case 3: // MiniProgram
              if (!item.title || !item.content || !item.jumpPath || !item.gh || !item.path) {
                Message.error(`第 ${i + 1} 条小程序消息的标题、内容、跳转路径、Gh 或路径未填写`);
                return;
              }
              break;
            case 2: // File
              if (!item.fileUrl) {
                Message.error(`第 ${i + 1} 条文件消息的文件 URL 未填写`);
                return;
              }
              break;
            default:
              Message.error(`第 ${i + 1} 条消息类型无效`);
              return;
          }
        }
  
        this.loading = true;
        try {
          await addForwardingSet(this.newForwardingSet);
          Message.success('转发设置添加成功');
          this.addForwardingSetDialogVisible = false;
          this.fetchForwardingSets();
          // 重置表单
          this.newForwardingSet = {
            name: '',
            WechatBotId: this.botwxid, // 保持 WechatBotId
            items: []
          };
        } catch (error) {
          Message.error('添加转发设置失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 显示编辑转发设置对话框
      showEditForwardingSetDialog(set) {
        this.editForwardingSetDialogVisible = true;
        // 深拷贝 set 以避免直接修改原数据
        this.editForwardingSet = JSON.parse(JSON.stringify(set));
      },
  
      // 添加新的 ForwardingItem（编辑转发设置对话框）
      addForwardingItemEdit() {
        this.editForwardingSet.items.push({
          type: null, // 初始化为 null，整数类型
          content: '',
          imageUrl: '',
          fileName: '',
          fileUrl: '',
          title: '',
          jumpUrl: '',
          app: '',
          jumpPath: '',
          gh: '',
          path: ''
        });
      },
  
      // 删除指定的 ForwardingItem（编辑转发设置对话框）
      removeForwardingItemEdit(index) {
        this.editForwardingSet.items.splice(index, 1);
      },
  
      // 当消息类型改变时，清空相关字段（编辑转发设置对话框）
      onTypeChangeEdit(index) {
        const item = this.editForwardingSet.items[index];
        switch(item.type) {
          case 0: // Text
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          case 1: // Image
            item.content = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          case 4: // ShareLink
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          case 3: // MiniProgram
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            break;
          case 2: // File
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
            break;
          default:
            // 清空所有字段
            item.content = '';
            item.imageUrl = '';
            item.fileName = '';
            item.fileUrl = '';
            item.title = '';
            item.jumpUrl = '';
            item.app = '';
            item.jumpPath = '';
            item.gh = '';
            item.path = '';
        }
      },
  
      // 处理编辑转发设置
      async handleEditForwardingSet() {
        // 验证每个 ForwardingItem 的必填字段
        for (let i = 0; i < this.editForwardingSet.items.length; i++) {
          const item = this.editForwardingSet.items[i];
          if (item.type === null || item.type === undefined) {
            Message.error(`第 ${i + 1} 条消息类型未选择`);
            return;
          }
          switch(item.type) {
            case 0: // Text
              if (!item.content) {
                Message.error(`第 ${i + 1} 条文本消息内容未填写`);
                return;
              }
              break;
            case 1: // Image
              if (!item.imageUrl || !item.fileName) {
                Message.error(`第 ${i + 1} 条图片消息的图片 URL 或文件名未填写`);
                return;
              }
              break;
            case 4: // ShareLink
              if (!item.title || !item.content || !item.jumpUrl || !item.app) {
                Message.error(`第 ${i + 1} 条分享链接消息的标题、内容、跳转 URL 或应用未填写`);
                return;
              }
              break;
            case 3: // MiniProgram
              if (!item.title || !item.content || !item.jumpPath || !item.gh || !item.path) {
                Message.error(`第 ${i + 1} 条小程序消息的标题、内容、跳转路径、Gh 或路径未填写`);
                return;
              }
              break;
            case 2: // File
              if (!item.fileUrl) {
                Message.error(`第 ${i + 1} 条文件消息的文件 URL 未填写`);
                return;
              }
              break;
            default:
              Message.error(`第 ${i + 1} 条消息类型无效`);
              return;
          }
        }
  
        this.loading = true;
        try {
          await updateForwardingSet(this.editForwardingSet);
          Message.success('转发设置更新成功');
          this.editForwardingSetDialogVisible = false;
          this.fetchForwardingSets();
          // 重置表单
          this.editForwardingSet = {
            id: 0,
            name: '',
            WechatBotId: this.botwxid, // 保持 WechatBotId
            items: []
          };
        } catch (error) {
          Message.error('更新转发设置失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 删除转发设置
      async handleDeleteForwardingSet(setId) {
        this.loading = true;
        try {
          await deleteForwardingSet(setId);
          Message.success('转发设置删除成功');
          this.fetchForwardingSets();
        } catch (error) {
          Message.error('删除转发设置失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 启动微信
      async handleStartWechat() {
        this.loading = true;
        try {
          const response = await startWechat(this.botwxid);
          this.qrCode = response.data.qrcode;
          this.flag = response.data.flag;
          this.qrCodeVisible = true;
        } catch (error) {
          Message.error('启动微信失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 获取可见的机器人列表
      async fetchVisibleBots() {
        this.loading = true;
        try {
          const response = await listVisibleBots(this.botwxid);
          this.visibleBots = response.data;
        } catch (error) {
          Message.error('获取可见机器人列表失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 扫描二维码完成
      async handleScanComplete() {
        this.loading = true;
        try {
          // 假设 listVisibleBots 需要 flag 参数
          const response = await listVisibleBots(this.botwxid);
          this.visibleBots = response.data;
          Message.success('登录成功');
          this.qrCodeVisible = false;
        } catch (error) {
          Message.error('获取可见机器人列表失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 选择机器人
      async handleSelectBot(bot) {
        this.loading = true;
        this.selectedBot = bot;
        try {
          const [friendsResponse, groupsResponse] = await Promise.all([
            getBotFriendList(bot.botwxid, bot.flag),
            getBotGroupList(bot.botwxid, bot.flag)
          ]);
          this.friends = friendsResponse.data;
          this.groups = groupsResponse.data;
        } catch (error) {
          Message.error('获取机器人数据失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 退出微信
      async handleExitWechat() {
        if (!this.selectedBot) return;
        this.loading = true;
        try {
          await exitWechat(this.selectedBot.botwxid, this.selectedBot.flag);
          this.selectedBot = null;
          this.friends = [];
          this.groups = [];
          Message.success('成功退出微信');
        } catch (error) {
          Message.error('退出微信失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      },
  
      // 发送消息（通过 setId 批量发送）
      async handleSendMessage() {
        if (!this.messageForm.forwardingSetId) {
          Message.warning('请选择转发设置');
          return;
        }
  
        if (this.messageForm.exclusionList.length > 10) {
          Message.error('排除列表最多只能选择10个好友或群组。');
          return;
        }
  
        this.loading = true;
        try {
          const payload = {
            BotWxid: this.botwxid,
            Wxid: this.messageForm.receiverId,
            SetId: this.messageForm.forwardingSetId,
            Flag: this.selectedBot.flag,
            ExclusionList: this.messageForm.exclusionList
          };
          const response = await sendMessage(payload);
          if (response.data) {
            Message.success('消息发送成功');
          } else {
            Message.error('部分消息发送失败');
          }
        } catch (error) {
          Message.error('发送消息失败: ' + (error.response?.data?.message || error.message));
        } finally {
          this.loading = false;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .forwarding-management {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #409EFF;
    color: white;
    padding: 0 20px;
    height: 60px;
  }
  
  .title {
    margin: 0;
    font-size: 20px;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
  }
  
  .header-actions .el-button {
    color: white;
    margin-left: 10px;
    font-size: 16px;
  }
  
  .el-main {
    padding: 20px;
    overflow-y: auto;
    flex: 1;
  }
  
  .login-card {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
  }
  
  .card-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .card-section {
    margin-bottom: 20px;
  }
  
  .message-form {
    margin-top: 20px;
  }
  
  .qr-code-image {
    width: 100%;
    max-width: 300px;
    margin: 0 auto 20px;
    display: block;
  }
  
  .forwarding-item {
    border: 1px solid #ebeef5;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .full-width-button {
    width: 100%;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      height: auto;
      padding: 10px;
    }
  
    .title {
      margin-bottom: 10px;
    }
  
    .el-main {
      padding: 10px;
    }
  
    .login-card {
      margin: 10px;
    }
  }
  </style>
  