<template>
  <div v-if="groupId" class="image-gen-config">
    <h2>群组 {{ groupId }} 的图像生成配置</h2>
    <el-form v-if="config" :model="config" label-width="120px">
      <el-form-item label="是否开启">
        <el-switch v-model="config.isOpen"></el-switch>
      </el-form-item>
      <el-form-item label="是否需要@">
        <el-switch v-model="config.requireAtMention"></el-switch>
      </el-form-item>
      <el-form-item label="触发关键词">
        <el-input v-model="config.triggerKeyword" placeholder="输入触发关键词，例如：画一张"></el-input>
      </el-form-item>
      <el-form-item label="模型选择">
        <el-select v-model="config.model" placeholder="选择模型">
          <el-option label="Stable Diffusion 3 Medium" :value="0"></el-option>
          <el-option label="Stable Diffusion XL Base" :value="1"></el-option>
          <el-option label="Stable Diffusion 2.1" :value="2"></el-option>
          <el-option label="SD Turbo" :value="3"></el-option>
          <el-option label="SDXL Turbo" :value="4"></el-option>
          <el-option label="FLUX.1 Schnell" :value="5"></el-option>
          <el-option label="SDXL Lightning" :value="6"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图像尺寸">
        <el-select v-model="selectedSize" placeholder="选择图像尺寸" @change="handleSizeChange">
          <el-option label="256x256" value="256x256"></el-option>
          <el-option label="512x512" value="512x512"></el-option>
          <el-option label="1024x1024" value="1024x1024"></el-option>
          <el-option label="768x512" value="768x512"></el-option>
          <el-option label="512x768" value="512x768"></el-option>
          <el-option label="1024x768" value="1024x768"></el-option>
          <el-option label="768x1024" value="768x1024"></el-option>
          <el-option label="自定义" value="custom"></el-option>
        </el-select>
        <el-input v-if="isCustomSize" v-model="config.imageSize" placeholder="输入自定义尺寸 (例如: 800x600)" style="margin-top: 10px;">
          <template #append>
            <el-button @click="validateCustomSize">确认</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="批次大小">
        <el-input-number v-model="config.batchSize" :min="1" :max="10"></el-input-number>
      </el-form-item>
      <el-form-item label="推理步数">
        <el-input-number v-model="config.numInferenceSteps" :min="1" :max="100"></el-input-number>
      </el-form-item>
      <el-form-item label="引导尺度">
        <el-slider v-model="config.guidanceScale" :min="0" :max="20" :step="0.1"></el-slider>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updateConfig">更新配置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div v-else>
    <h2>错误：未提供群组 ID</h2>
    <p>请确保在 URL 中包含有效的群组 ID。</p>
  </div>
</template>

<script>
import { getImageGenConfig, updateImageGenConfig } from '@/api';

export default {
  props: ['botwxid', 'roomwxid'],
  name: 'ImageGenConfig',
  data() {
    return {
      groupId: null,
      config: null,
      selectedSize: '',
      isCustomSize: false,
    };
  },
  created() {
    this.groupId = this.roomwxid;
    if (this.groupId) {
      this.fetchConfig();
    }
  },
  methods: {
    async fetchConfig() {
      try {
        const response = await getImageGenConfig(this.groupId);
        if (response.data.code === 2000) {
          this.config = response.data.data;
          this.selectedSize = this.config.imageSize;
        } else {
          this.$message.error(response.data.message);
        }
      } catch (error) {
        console.error('获取图像生成配置失败:', error);
        this.$message.error('获取图像生成配置失败');
      }
    },
    async updateConfig() {
      try {
        const response = await updateImageGenConfig(this.config);
        if (response.data.code === 2000) {
          this.$message.success('图像生成配置更新成功');
        } else {
          this.$message.error(response.data.message);
        }
      } catch (error) {
        console.error('更新图像生成配置失败:', error);
        this.$message.error('更新图像生成配置失败');
      }
    },
    handleSizeChange(value) {
      if (value === 'custom') {
        this.isCustomSize = true;
      } else {
        this.isCustomSize = false;
        this.config.imageSize = value;
      }
    },
    validateCustomSize() {
      const sizeRegex = /^\d+x\d+$/;
      if (sizeRegex.test(this.config.imageSize)) {
        this.$message.success('自定义尺寸已确认');
        this.selectedSize = 'custom';
      } else {
        this.$message.error('请输入有效的尺寸格式 (例如: 800x600)');
      }
    },
  },
};
</script>

<style scoped>
.image-gen-config {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
</style>