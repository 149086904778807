<!-- JokeConfig.vue -->
<template>
    <div v-if="groupId" class="joke-config">
      <h2>群组 {{ groupId }} 的笑话配置</h2>
      <el-form v-if="config" :model="config" label-width="120px">
        <el-form-item label="是否启用">
          <el-switch v-model="config.isEnabled" @change="updateIsEnabled"></el-switch>
        </el-form-item>
        <el-form-item label="触发关键词">
          <el-input v-model="config.keyword" placeholder="请输入触发笑话的关键词"></el-input>
        </el-form-item>
        <el-form-item label="App ID">
          <el-input v-model="config.appId" placeholder="请输入 App ID"></el-input>
        </el-form-item>
        <el-form-item label="App Secret">
          <el-input v-model="config.appSecret" placeholder="请输入 App Secret" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateConfig">更新配置</el-button>
          <el-button type="success" @click="fetchJokes">获取新笑话</el-button>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <h3>随机笑话预览</h3>
      <el-button @click="getRandomJoke" :disabled="!config || !config.isEnabled">获取随机笑话</el-button>
      <el-card class="joke-preview" v-if="randomJoke">
        <div v-html="randomJoke"></div>
      </el-card>
    </div>
    <div v-else>
      <h2>错误：未提供群组 ID</h2>
      <p>请确保在 URL 中包含有效的群组 ID。</p>
    </div>
  </template>
  
  <script>
  import { getJokeConfig, updateJokeConfig, setJokeConfigIsEnabled, fetchAndSaveJokes, getRandomJoke } from '@/api';
  
  export default {
    name: 'JokeConfig',
    props: ['roomwxid'],
    data() {
      return {
        config: null,
        randomJoke: null,
        groupId: null,
      };
    },
    created() {
      this.groupId = this.roomwxid;
      if (this.groupId) {
        this.fetchConfig();
      }
    },
    methods: {
      async fetchConfig() {
        try {
          const response = await getJokeConfig(this.groupId);
          if (response.data.code === 2000) {
            this.config = response.data.data;
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('获取笑话配置失败:', error);
          this.$message.error('获取笑话配置失败');
        }
      },
      async updateConfig() {
        try {
          const response = await updateJokeConfig(this.config);
          if (response.data.code === 2000) {
            this.$message.success('笑话配置更新成功');
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('更新笑话配置失败:', error);
          this.$message.error('更新笑话配置失败');
        }
      },
      async updateIsEnabled(isEnabled) {
        try {
          const response = await setJokeConfigIsEnabled(this.groupId, isEnabled);
          if (response.data.code === 2000) {
            this.$message.success(`笑话功能已${isEnabled ? '启用' : '禁用'}`);
          } else {
            this.$message.error(response.data.message);
            // 如果API调用失败，恢复开关状态
            this.config.isEnabled = !isEnabled;
          }
        } catch (error) {
          console.error('更新启用状态失败:', error);
          this.$message.error('更新启用状态失败');
          // 如果API调用失败，恢复开关状态
          this.config.isEnabled = !isEnabled;
        }
      },
      async fetchJokes() {
        try {
          const response = await fetchAndSaveJokes(this.groupId);
          if (response.data.code === 2000) {
            this.$message.success('成功获取并保存新笑话');
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('获取新笑话失败:', error);
          this.$message.error('获取新笑话失败');
        }
      },
      async getRandomJoke() {
        try {
          const response = await getRandomJoke(this.groupId);
          if (response.data.code === 2000) {
            this.randomJoke = response.data.data;
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error('获取随机笑话失败:', error);
          this.$message.error('获取随机笑话失败');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .joke-config {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  .joke-preview {
    margin-top: 20px;
  }
  </style>